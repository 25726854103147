<template lang="pug">
  .header(:class="{ header_sticky: isStickyHeader }")
    .title
      h1(:key="localizedCurrentRouteName") {{ localizedCurrentRouteName }}
    .user
      ImpersonateButton(v-if="currentUser && currentUser.impersonated")
      LanguageSelect.select-language
      UserMenu
</template>

<script>
  // misc
  import { mapState } from "vuex"
  import { ROUTES_WITH_STICKY_HEADERS } from "@/config/constants"

  export default {
    components: {
      UserMenu: () => import("@/components/layout/Header/UserMenu"),
      ImpersonateButton: () => import("@/components/layout/Header/ImpersonateButton"),
      LanguageSelect: () => import("@/components/LanguageSelect")
    },

    computed: {
      ...mapState(["currentUser"]),

      localizedCurrentRouteName() {
        const { title } = this.$route.meta
        if (!title) return

        return this.$t(`header.title.${title}`)
      },

      isStickyHeader() {
        const { title } = this.$route.meta

        return ROUTES_WITH_STICKY_HEADERS.includes(title)
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"
  @import "@/assets/styles/transitions.sass"

  .header
    align-items: center
    border-bottom: 1px solid $default-gray-light
    display: flex
    justify-content: space-between
    margin-bottom: $header-margin-bottom
    height: $header-height
    padding: 20px 0
    width: 100%

    &_sticky
      background-color: #fff
      position: sticky
      top: 0
      z-index: 2
      margin-bottom: 0

    .title
      h1
        color: $default-black
        font-family: Helvetica
        font-size: 1.5rem
        letter-spacing: 0.4px
        line-height: 1.5
        margin-bottom: 0

    .user
      align-items: center
      display: flex
      justify-content: space-between
      gap: 1rem

  @media (min-width: 992px)
    .header
      .title
        h1
          font-size: 1.5rem

  @media (min-width: 785px) and (max-width: 992px)
    .header
      .title
        h1
          font-size: 1.2rem

  @media (min-width: 500px) and (max-width: 785px)
    .header
      .title
        h1
          font-size: 1.1rem

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active)
    .header
      .title
        h1
          font-family: sans-serif !important
</style>
